<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>

        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
        >
          <template slot="item" slot-scope="row">
            <tr :key="row.item.id">
              <td>
                {{ row.item.leavePolicy.leaveType.name }}
              </td>
              <td>
                {{ row.item.leavePolicy.accrualCalculatedCount }}
              </td>
              <td>
                {{ row.item.used.toFixed(2) || 0 }}
              </td>
              <td>{{ totalBalance(row.item) }}</td>
              <td>
                {{ row.item.previousBalance || 0 }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description shifts Component Logic
   * @date 25.07.2020
   */
  import { LEAVE_ACCRUAL } from "../query";

  export default {
    name: "LeaveAccruals",
    data: vm => ({
      table: {
        loading: false,
        data: [],
        criteria: {
          employees: [],
          beginDate: null,
          endDate: null
        },
        options: {},
        pagination: {
          rowsPerPage: 50,
          sortBy: "date",
          descending: false
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("leave.accruals.name"),
            value: "leavePolicy.leaveType.name",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("leave.accruals.count"),            
            value: "leavePolicy.leaveType.accrualCount",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("leave.accruals.used"),
            title: vm.$t("time.timesheet.shift"),
            value: "used",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: "200px"
          },
          {
            text: vm.$t("leave.request.leave_balance"),
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("leave.accruals.previous_balance"),
            title: vm.$t("time.timesheet.ewt_title"),
            value: "previousBalance",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler() {
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      totalBalance(leaveAccrual) {
        let total = leaveAccrual.previousBalance + leaveAccrual.leavePolicy.accrualCalculatedCount - leaveAccrual.used;
        const fixedTotal = total.toFixed(2);
        return Number(fixedTotal);
      },
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: LEAVE_ACCRUAL,
            variables: {
              employee: this.$store.state.auth.user.id,
              onlyRequestable: true
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.table.data = data.leaveAccruals;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>

<style scoped></style>
